<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-6
          h1 フィードバック
          p 以下のフォームにご入力いただき「入力内容の確認」ボタンをクリックしてください
          p （通常のお問い合わせは
            router-link(to="/inquiry_form/input") こちら
            |よりお願い致します。）
          .alert.alert-danger(v-if="messages.length > 0") 
            li(v-for="message in messages") {{ message }}
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              label 内容
              br
              .form-check.form-check-inline
                input#kind1.form-check-input(type="radio" v-model="kind" name="kind" value="バグ（不具合）報告" required)
                label.form-check-label.radio-label(for="kind1") バグ（不具合）報告
              .form-check.form-check-inline
                input#kind2.form-check-input(type="radio" v-model="kind" name="kind" value="岩場情報のご提供" required)
                label.form-check-label.radio-label(for="kind2") 岩場情報のご提供
              .invalid-feedback(ref="kindError") フィードバック内容を選択してください
            .form-group
              label(for="Inquiry") 本文
              textarea#Inquiry.form-control(type="Inquiry" rows="5" v-model="Inquiry" required)
              .invalid-feedback お問い合わせ内容を入力してください
              div.text-right ※個人情報の入力はご遠慮ください。
            input.btn.btn-primary(type="submit" value="入力内容の確認" v-bind:disabled="isDisabled")
</template>

<script>
export default {
  data() {
    return {
      kind: this.$route.params.kind,
      Inquiry: this.$route.params.Inquiry,
      messages: [],
      isDisabled: false,
    }
  },
  methods: {
    exec(e) {
      this.isDisabled = true

      //表示中のメッセージをクリア
      this.messages = []

      //フィードバック入力チェック
      if (this.kind) {
        this.$refs.kindError.style.display = ''
      }else {
        //ラジオボタンの選択がない場合
        this.$refs.kindError.style.display = 'block'
        this.isDisabled = false
        return
      }
    
      //入力チェック
      if(this.Inquiry.trim().length == 0){
        this.messages.push('本文を入力してください。')
        this.isDisabled = false
        return
      }
    
      //エラー処理
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation()
        this.isDisabled = false
        return
      }
      
      this.$router.push({ name: 'feed_back_confirm', params: {kind: this.kind, Inquiry: this.Inquiry}}) //ページ移動
    }
  }
}
</script>