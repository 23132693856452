var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("h1", [_vm._v("フィードバック")]),
          _c("p", [
            _vm._v(
              "以下のフォームにご入力いただき「入力内容の確認」ボタンをクリックしてください"
            )
          ]),
          _c(
            "p",
            [
              _vm._v("（通常のお問い合わせは"),
              _c("router-link", { attrs: { to: "/inquiry_form/input" } }, [
                _vm._v("こちら")
              ]),
              _vm._v("よりお願い致します。）")
            ],
            1
          ),
          _vm.messages.length > 0
            ? _c(
                "div",
                { staticClass: "alert alert-danger" },
                _vm._l(_vm.messages, function(message) {
                  return _c("li", [_vm._v(_vm._s(message))])
                }),
                0
              )
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "needs-validation",
              attrs: { novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.exec($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("内容")]),
                _c("br"),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kind,
                        expression: "kind"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      id: "kind1",
                      type: "radio",
                      name: "kind",
                      value: "バグ（不具合）報告",
                      required: ""
                    },
                    domProps: {
                      checked: _vm._q(_vm.kind, "バグ（不具合）報告")
                    },
                    on: {
                      change: function($event) {
                        _vm.kind = "バグ（不具合）報告"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "kind1" }
                    },
                    [_vm._v("バグ（不具合）報告")]
                  )
                ]),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kind,
                        expression: "kind"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      id: "kind2",
                      type: "radio",
                      name: "kind",
                      value: "岩場情報のご提供",
                      required: ""
                    },
                    domProps: { checked: _vm._q(_vm.kind, "岩場情報のご提供") },
                    on: {
                      change: function($event) {
                        _vm.kind = "岩場情報のご提供"
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label radio-label",
                      attrs: { for: "kind2" }
                    },
                    [_vm._v("岩場情報のご提供")]
                  )
                ]),
                _c(
                  "div",
                  { ref: "kindError", staticClass: "invalid-feedback" },
                  [_vm._v("フィードバック内容を選択してください")]
                )
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "Inquiry" } }, [_vm._v("本文")]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.Inquiry,
                      expression: "Inquiry"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "Inquiry",
                    type: "Inquiry",
                    rows: "5",
                    required: ""
                  },
                  domProps: { value: _vm.Inquiry },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.Inquiry = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("お問い合わせ内容を入力してください")
                ]),
                _c("div", { staticClass: "text-right" }, [
                  _vm._v("※個人情報の入力はご遠慮ください。")
                ])
              ]),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  value: "入力内容の確認",
                  disabled: _vm.isDisabled
                }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }